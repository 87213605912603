import React from "react";

/**
 *
 * @param {*} Component
 * @param {*} firebasePathProvider as (props) => {key: {path, isCollection}}
 */
export default function withFocus(Component) {
  return class FocusComponent extends React.Component {
    componentDidMount() {
      window.addEventListener("focus", this.handleFocus);
    }

    componentWillUnmount() {
      window.removeEventListener("focus", this.handleFocus);
    }

    handleFocus = () => {
      this.forceUpdate();
    };

    render() {
      return <Component {...this.props} />;
    }
  };
}

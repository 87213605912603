import React, { Component } from 'react'
import orderBy from "lodash/orderBy";
import { Table, Button } from 'react-bootstrap';
import moment from "moment";
import { fromNow } from '../utils';

class Rewards extends Component {

  render() {
    const { user, rewards, transactions, onWin, onCancel } = this.props
    const today = moment().format("YYYY-MM-DD");
    const availableRewards = orderBy(
      rewards.filter(r => {
        if (!r.availableFor) return true;
        return r.availableFor.indexOf(user.id) !== -1;
      }),
      r => r.order
    );
    return (
      <Table>
        <tbody>
          {availableRewards.map(item => {
            const lastOccurence = transactions.find(t => t.rewardId === item.id);
            // const canBuy = points >= item.cost;
            const hasDoneToday = lastOccurence && lastOccurence.date === today;
            return (
              <tr key={item.id} className={!hasDoneToday ? "item-available" : "item-unavailable"}>
                <td style={{ verticalAlign: "middle", paddingTop: 0, paddingBottom: 0 }}>
                  <div className="item-name">{item.name}</div>
                  {lastOccurence && (
                    <div className="item-details">
                      <small>{fromNow(lastOccurence.datetime)}</small>
                    </div>
                  )}
                </td>
                {/* <td style={{ textAlign: "right", verticalAlign: "middle" }}>{item.points}</td> */}
                <td className="td--button">
                  <Button
                    variant={hasDoneToday ? "success" : "outline-success"}
                    onClick={() => (hasDoneToday ? onCancel(lastOccurence) : onWin(item))}
                  >
                    ✓
                      </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    )
  }
}

export default Rewards

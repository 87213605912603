import React from "react";

/**
 *
 * @param {*} Component
 * @param {*} firebasePathProvider as (props) => {key: {path, isCollection}}
 */
export default function withFirebase(Component, firebasePathProvider) {
  return class FirebaseComponent extends React.Component {
    state = {};

    constructor(props) {
      super(props);

      this.firebasePaths = firebasePathProvider(props);
    }
    componentDidMount() {
      this._isMounted = true;

      this.firebaseListeners = {};

      for (const key in this.firebasePaths) {
        const path = this.firebasePaths[key];
        const isCollection = !path._key; // Documents have a key, collections don't...

        const firebaseInfo = {
          key,
          path
        };
        this.firebaseListeners[key] = firebaseInfo;
        if (isCollection) {
          firebaseInfo.data = [];
          firebaseInfo.unsubscribe = path.onSnapshot(
            querySnapshot => {
              // console.log("onSnapshot", key, querySnapshot);
              if (!this._isMounted) return;
              const data = querySnapshot.docs.map(d => ({
                id: d.id,
                ...d.data()
              }));
              firebaseInfo.data = data;
              this.setState({ [key]: data });
            },
            err => {
              console.log(`Encountered error: ${err}`);
            }
          );
        } else {
          firebaseInfo.unsubscribe = path.onSnapshot(
            querySnapshot => {
              if (!this._isMounted) return;
              this.setState({ [key]: querySnapshot.data() });
            },
            err => {
              console.log("Encountered error", err);
            }
          );
        }
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
      for (const key in this.firebaseListeners) {
        if (this.firebaseListeners[key].unsubscribe) {
          this.firebaseListeners[key].unsubscribe();
        }
      }
    }

    render() {
      for (const key in this.firebasePaths) {
        // Some data is still missing...
        if (!this.state[key]) {
          return <div className="dashboard-details-screen" />;
        }
      }
      return <Component {...this.props} {...this.state} />;
    }
  };
}

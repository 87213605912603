import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { db } from "../core/firebase";
import moment from "moment";
import "moment/locale/fr";
import withFirebase from "../hoc/withFirebase";
import withFocus from "../hoc/withFocus";
import Rewards from "./Rewards";
import Shop from "./Shop";
moment.locale("fr");


class User extends Component {
  dbPath = () => db.collection("users").doc(this.props.user.id);

  handleWin = reward => {
    const { user } = this.props;
    console.log("win", reward);

    const date = moment().format("YYYY-MM-DD");
    const id = date + "_" + reward.id;
    this.dbPath()
      .collection("transactions")
      .doc(id)
      .set({ ...reward, id, type: "reward", rewardId: reward.id, date, datetime: new Date() });
    this.dbPath().update({ points: user.points + reward.points });
  };

  handleCancelWin = transaction => {
    const { user } = this.props;
    if (window.confirm("Etes-vous sûr de vouloir annuler ce point ?")) {
      this.dbPath()
        .collection("transactions")
        .doc(transaction.id)
        .delete();
      this.dbPath().update({ points: user.points - transaction.points });
    }
  };

  handleBuy = shopItem => {
    const { user } = this.props;
    console.log("buy", shopItem);

    const date = moment().format("YYYY-MM-DD");
    const dateId = moment().format("YYYY-MM-DD_HH-mm");
    const id = dateId + "_" + shopItem.id;
    this.dbPath()
      .collection("transactions")
      .doc(id)
      .set({ ...shopItem, id, type: "shopItem", shopId: shopItem.id, date, datetime: new Date() });
    this.dbPath().update({ points: user.points - shopItem.cost });
  };

  render() {
    const { user, shop, rewards, transactions } = this.props;

    // console.log("lastTransactions", lastTransactions);
    return (
      <div className="content">
        {/* Hello {user.name} */}
        <Card border="success">
          <Card.Header>Gagner des points</Card.Header>
          <Rewards user={user} rewards={rewards} onWin={this.handleWin} onCancel={this.handleCancelWin} transactions={transactions} />
        </Card>
        <Card border="danger">
          <Card.Header>Utiliser les points</Card.Header>
          <Shop user={user} shop={shop} onBuy={this.handleBuy} transactions={transactions} />
        </Card>
      </div>
    );
  }
}

export default withFocus(
  withFirebase(User, ({ user }) => ({
    transactions: db
      .collection("users")
      .doc(user.id)
      .collection("transactions")
      .orderBy("datetime", "desc")
  }))
);
